<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				站内消息列表
				
			</div>
			<div class="Right">
				
				
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">内容</div>
				<div class="C2">状态</div>
				<div class="C2">类型</div>
				<div class="C4">创建时间</div>
				<div class="C5">目标用户ID</div>
			</div>
			
			<div class="One" v-for="item in SmsList" :key="item.Id">
				<li>
					<div class="C1">{{item.Title}}
					<p style="color: rgba(0,0,0,0.4);">
						{{item.Detail}}
					</p>
					</div>
					<div class="C2">{{item.StatusName}}</div>
					<div class="C2">
						其它类型
					</div>
					<div class="C4">{{item.CreatedAt}}</div>
					<div class="C5">{{item.ToUserId}}</div>
					
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination,DatePicker} from 'element-ui'
	export default {
	  name: 'NoticeIndex',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				  Mobile:'',
				  SmsAction:'',
				  CreatedAfter:'',
				  CreatedBefore:'',
			  },
			  SmsList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-date-picker':DatePicker,
	  },
	  created() {
	  	this.StartSearching()
	  },
	  methods:{
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Code',
		  			Class: 'Notice',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
					this.SmsList = res.Data.NoticeList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
					this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Code',
		  			Class: 'Notice',
		  			Action: 'Count',
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  					this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  					this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.User .Top .Right span{
	margin-left: 10px;
}
.User .Top .Right label{
	display: block;
	font-size: 0.9rem;
	color: rgba(0,0,0,0.4);
	margin-bottom: 5px;
}
.User .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C5{
	width: 250px;
	color: rgba(0,0,0,0.4);
}
.C1{
	padding-left: 10px;
}

.C6{
	width: 100px;
	text-align: right;
	padding-right: 10px;
}
.C1{
	flex: 1;
}
.C2,.C3{
	width: 110px;
}
.C4{
	width: 150px;
	color: rgba(0,0,0,0.4);
}
.C5{
	width: 340px;
	color: rgba(0,0,0,0.4);
	text-align: center;
}
.User .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.User .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.User .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
